import React, { useState, useEffect } from "react"
import _ from "lodash"
import SearchBar from "../components/search/search-bar/search-bar"
import WebsiteLayout from "../components/layout/website/website-layout/website-layout"
import SEO from "../services/seo"
import photoService from "../services/photo"
import storyService from "../services/story"
import { getUsers } from "../services/user"
import StandardCard from "../components/media-output/cards/standard-card/standard-card"
import util from "../services/util"
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from "gatsby"
import styles from "./index.module.scss"
import DirectLink from "../components/common/direct-link/direct-link"
import classNames from "classnames"
import intro_1 from "./home/intro_01.jpg"
import intro_2 from "./home/intro_02.jpg"
import intro_3 from "./home/intro_03.jpg"
import intro_4 from "./home/intro_04.jpg"
import ourMedia_1 from "./home/OurMedia_01.jpg"
import ourMedia_2 from "./home/OurMedia_02.jpg"
import ourMedia_3 from "./home/OurMedia_03.jpg"
import footerImage from "./home/home-footer.jpg"

const HomePage = () => {
  const data = useStaticQuery(graphql`
    query PageHomeQuery {
      allStrapiHome {
        edges {
          node {
            tagline
            infoline
            orgTitle
            orgDesc
            footerCallToAction
          }
        }
      }
    }
  `)

  const searchPath = "/search/"

  const onSearch = async types => {
    await navigate(searchPath, { state: { type: { value: types } } })
  }

  const [isFadeUp, setFadeUp] = useState(false)
  const [featuredPhotos, setFeaturedPhotos] = useState([])
  const [featuredStories, setFeaturedStories] = useState([])
  const [users, setUsers] = useState([])

  function setScrollFadeUp() {
    if (window.scrollY > window.innerHeight / 3) {
      setFadeUp(true)
    } else {
      setFadeUp(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", setScrollFadeUp)
  })

  useEffect(() => {
    photoService.findByBranch('fhaa').then(
      response => {
        setFeaturedPhotos(response.photos)
      },
      error => {
        console.log("findByBranch photos: ERROR", error)
      }
    )

    storyService.findFeaturedStories('FHAA').then(
      response => {
        setFeaturedStories(response.stories)
      },
      error => {
        console.log("findFeaturedStories stories: ERROR", error)
      }
    )
  }, [])
  
  console.log('Stories:', featuredStories)

  return (
    <WebsiteLayout>
      <SEO title="Forest History Association of Alberta" />
      <div className={styles.wrapper}>
        <div className={styles.heroWrap}>
          <div className={styles.heroContent}>
            <h1 className={styles.heroTitle}>
              {data.allStrapiHome.edges[0].node.tagline}
            </h1>
            <p className={styles.heroSubtitle}>
              {data.allStrapiHome.edges[0].node.infoline}
            </p>
            <SearchBar />
          </div>
        </div>
        <div className={styles.intro}>
          <ul
            className={classNames(styles.photos, isFadeUp ? styles.fadeup : "", isFadeUp ? styles.visiable : "")}
          >
            {_.map(featuredPhotos, (photo) => (
              <li>
                <img src={photo.thumbnailUrl} />
              </li>
            ))}
          </ul>
          <div className={styles.orgTitle}>
            <h1>{data.allStrapiHome.edges[0].node.orgTitle}</h1>
            <p>{data.allStrapiHome.edges[0].node.orgDesc}</p>
          </div>
          <div className={styles.orgTitle}>
            <h1>Featured Articles</h1>
          </div>
          <ul
            className={classNames(styles.photos, isFadeUp ? styles.fadeup : "", isFadeUp ? styles.visiable : "")}
          >
            {_.map(featuredStories, (story) => (
              <li>
                {/* <div className={styles.cards}> */}
                  <StandardCard
                    key={story._id}
                    data={util.fixUrls(story)}
                    homePage
                  />
              </li>
            ))}
          </ul>
        </div>
        {/* {data.allStrapiRecent.edges.length > 0 ? (
          <div className={styles.recentArticle}>
            <h1 className={styles.headline}>Recent Articles</h1>
            <div className={styles.articleWrap}>
              <div className={styles.mainArticle}>
                <div className={styles.story}>
                  <div className={styles.thumbnail}>
                    <img src="https://cdn.pixabay.com/photo/2020/03/19/18/52/bend-4948376_960_720.jpg" />
                  </div>
                  <h4 className={styles.title}>
                    {data.allStrapiRecent.edges[0].node.title}
                  </h4>
                  <h6 className={styles.truncateContent}>
                    The Forest History Society is a nonprofit library and
                    archive dedicated to collecting, preserving, and
                    disseminating forest and conservation history for all to
                    use. The Society links the past to the future while
                    reminding us about our important forest heritage.
                  </h6>
                 <DirectLink
                    to={
                      "/detailed-file-view?mediaId=" +
                      data.allStrapiRecent.edges[0].node.id +
                      "&" +
                      "mediaType=Story"
                    }
                    text="Read Story"
                  />
                </div>
              </div>

              <ul className={styles.articleList}>
                {data.allStrapiRecent.edges.slice(1).map(a => (
                  <li className={styles.story}>
                    <div className={styles.thumbnail}>
                      <img src="https://cdn.pixabay.com/photo/2020/03/19/18/52/bend-4948376_960_720.jpg" />
                    </div>
                    <div className={styles.info}>
                      <h4 className={styles.title}>{a.node.title}</h4>
                      <h6 className={styles.truncateContent}>
                        The Forest History Society is a nonprofit library and
                        archive dedicated to collecting, preserving, and
                        disseminating forest and conservation history for all to
                        use. The Society links the past to the future while
                        reminding us about our important forest heritage.
                      </h6>
                      <DirectLink
                        to={
                          "/detailed-file-view?mediaId=" +
                          a.node.id +
                          "&" +
                          "mediaType=Story"
                        }
                        text="Read Story"
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )} */}

        <div className={styles.ourMedias}>
          <h1 className={styles.headline}>Our Media</h1>

          <div className={styles.category}>
            <button
              type="button"
              className={classNames(styles.type, styles.typePhoto)}
              onClick={() => onSearch(["Photo"])}
            >
              <h1>Photos</h1>
              <img src={ourMedia_1} />
            </button>
            <button
              type="button"
              className={classNames(styles.type, styles.typeVideo)}
              onClick={() => onSearch(["Video"])}
            >
              <h1>Videos</h1>
              <img src={ourMedia_2} />
            </button>
            <button
              type="button"
              className={classNames(styles.type, styles.typeDocument)}
              onClick={() => onSearch(["Document", "Audio", "Archive"])}
            >
              <h1>Documents &amp; Other Media</h1>
              <img src={ourMedia_3} />
            </button>
          </div>
        </div>
        <div className={styles.footerCallToAction}>
          <div className={styles.container}>
            <div className={styles.footerPhoto}>
              <div className={styles.photoGroup}>
                <div className={classNames(styles.photoTop, styles.mask)}>
                  <img src={footerImage} />
                  {/* <img
                  src={
                    data.allStrapiHome.edges[0].node.footerImage.thumbnailUrl
                  }
                /> */}
                </div>
                <div className={classNames(styles.photoBottom, styles.mask)}>
                  <img src={footerImage} />
                  {/* <img
                  src={
                    data.allStrapiHome.edges[0].node.footerImage.thumbnailUrl
                  }
                /> */}
                </div>
              </div>
            </div>
            <div className={styles.footerContent}>
              <h1>{data.allStrapiHome.edges[0].node.footerCallToAction}</h1>
              <SearchBar />
            </div>
          </div>
        </div>
      </div>
    </WebsiteLayout>
  )
}

export default HomePage
